import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const FileUploader = ({ onUploadComplete, setIsProcessing }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files).filter(file => file.type === 'application/pdf');
    addFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files).filter(file => file.type === 'application/pdf');
    addFiles(files);
  };

  const addFiles = (files) => {
    setError('');
    const newFiles = [...selectedFiles, ...files].slice(0, 10);
    setSelectedFiles(newFiles);
    if (newFiles.length > 10) {
      setError('Maximum 10 files allowed. Only the first 10 files have been selected.');
    }
  };

  const removeFile = (index) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
    setError('');
  };

  const handleSubmit = async () => {
    if (selectedFiles.length === 0) {
      setError('Please select at least one PDF file to upload.');
      return;
    }

    setIsProcessing(true);
    setError('');
    const formData = new FormData();
    selectedFiles.forEach(file => formData.append('files', file));

    try {
      const response = await fetch(`${config.apiUrl}/upload_pdfs`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload failed. Please try again.');
      }

      const result = await response.json();
      onUploadComplete(result.conversion_ids);
      navigate('/processing'); // Navigate to the processing page after successful upload
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An error occurred during upload. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="file-uploader max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Upload Your PDF Files (Max 10)</h2>
      <div 
        className={`drop-area border-2 border-dashed p-8 mb-4 text-center cursor-pointer 
                    ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
      >
        <p className="text-gray-600 mb-2">Drag and drop your PDF files here, or click to select</p>
        <button className="bg-[#92ACD1] text-white px-4 py-2 rounded hover:bg-[#92ACD1] transition-colors">
          Select PDFs
        </button>
        <input 
          type="file" 
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileSelect}
          accept=".pdf"
          multiple
        />
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {selectedFiles.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Selected files:</h3>
          <ul className="space-y-2">
            {selectedFiles.map((file, index) => (
              <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                <span className="truncate">{file.name}</span>
                <button 
                  onClick={() => removeFile(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <button 
  className="w-full bg-[#3498db] text-white px-4 py-2 rounded hover:bg-[#2980b9] transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
  onClick={handleSubmit} 
  disabled={selectedFiles.length === 0}
      >
        Submit
      </button>
    </div>
  );
};

export default FileUploader;