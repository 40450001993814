import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const AIOutput = ({ aiOutputs }) => {
  const [error, setError] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();

  const handleSaveOutput = async () => {
    try {
      setError('');
      setIsGenerating(true);
      console.log("Starting save output process");

      const response = await fetch(`${config.apiUrl}/generate_excel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ texts: aiOutputs }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate Excel file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'invoice_data.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      console.log("Excel file downloaded successfully");

      alert('Excel file downloaded successfully!');
    } catch (error) {
      console.error('Error saving output:', error);
      setError(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleBack = () => {
    navigate('/upload');
  };

  const handleContinue = () => {
    navigate('/');
  };

  return (
    <div className="ai-output-display max-w-3xl mx-auto p-6 bg-white rounded shadow">
      <h2 className="text-2xl font-bold mb-4">AI Processing Results</h2>
      {error && <div className="error-message text-red-500 mt-4">{error}</div>}
      <div className="flex space-x-4 mt-6">
        <button
          onClick={handleBack}
          className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
        >
          Back to Upload
        </button>
        <button
          onClick={handleContinue}
          className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
        >
          Back to Homepage
        </button>
        <button
          onClick={handleSaveOutput}
          className="bg-[#92ACD1] hover:bg-opacity-90 text-white px-4 py-2 rounded"
          disabled={isGenerating}
        >
          {isGenerating ? 'Generating Excel...' : 'Save All Outputs'}
        </button>
      </div>
    </div>
  );
};

export default AIOutput;