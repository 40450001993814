import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom'; // Import useNavigate at the top
import LandingPage from './components/LandingPage';
import FileUploader from './components/FileUploader';
import ProcessingInterface from './components/ProcessingInterface';
import InstructionInput from './components/InstructionInput';
import AIOutput from './components/AIOutput';
import config from './config';

const App = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [conversionIds, setConversionIds] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [aiOutputs, setAiOutputs] = useState([]);
  const [convertedTexts, setConvertedTexts] = useState([]);
  const [savedDatasets, setSavedDatasets] = useState([]);

  useEffect(() => {
    const storedDatasets = localStorage.getItem('savedDatasets');
    if (storedDatasets) {
      setSavedDatasets(JSON.parse(storedDatasets));
    }
  }, []);

  const handleSaveDataset = (newDatasets) => {
    setSavedDatasets(newDatasets);
    localStorage.setItem('savedDatasets', JSON.stringify(newDatasets));
  };

  const handleUploadComplete = (ids) => {
    setConversionIds(ids);
  };

  const handleInstructionsComplete = (id) => {
    setFileId(id);
  };

  const handleCheckConvertedFiles = async () => {
    if (conversionIds.length === 0) return;
    setIsProcessing(true);
    try {
      const texts = await Promise.all(
        conversionIds.map(async (id) => {
          const response = await fetch(`${config.apiUrl}/get_converted_file/${id}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch converted file with ID ${id}`);
          }
          const text = await response.text();
          return { id, text };
        })
      );
      setConvertedTexts(texts);
    } catch (error) {
      console.error('Error fetching converted files:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleContinueToAI = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch(`${config.apiUrl}/process_with_anthropic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ conversionIds, fileId }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to process with AI API');
      }
      const result = await response.json();
      setAiOutputs(result.outputs);
      // Navigation handled within child component after AI processing
    } catch (error) {
      console.error('Error:', error);
      // Optionally, set an error state to display to the user
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <main className="flex-grow container mx-auto px-6 py-8">
          {isProcessing && <ProcessingInterface message="Processing..." />}
          
          <Routes>
            <Route path="/" element={<LandingPage />} />
            
            <Route path="/upload" element={
              <FileUploader 
                onUploadComplete={handleUploadComplete} 
                setIsProcessing={setIsProcessing} 
              />
            } />
            
            <Route path="/processing" element={
              conversionIds.length > 0 ? (
                <ProcessingPage 
                  conversionIds={conversionIds}
                  convertedTexts={convertedTexts}
                  handleCheckConvertedFiles={handleCheckConvertedFiles}
                  setIsProcessing={setIsProcessing}
                />
              ) : (
                <Navigate to="/upload" />
              )
            } />
            
            <Route path="/instructions" element={
              <InstructionInput 
                onInstructionsComplete={handleInstructionsComplete} 
                setIsProcessing={setIsProcessing}
                savedDatasets={savedDatasets}
                onSaveDataset={handleSaveDataset}
              />
            } />
            
            <Route path="/ai-processing" element={
              <AIProcessingPage 
                handleContinueToAI={handleContinueToAI}
                setIsProcessing={setIsProcessing}
              />
            } />
            
            <Route path="/ai-output" element={
              <AIOutput 
                aiOutputs={aiOutputs} 
              />
            } />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

// Define ProcessingPage outside of App component
const ProcessingPage = ({ setIsProcessing }) => {
  const navigate = useNavigate();

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Please add insturction for AI to continue...</h2>
      <div className="flex space-x-4">
        <button
          onClick={() => navigate('/upload')}
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
        >
          Cancel
        </button>
        <button
          onClick={() => navigate('/instructions')}
          className="bg-[#92ACD1] hover:bg-opacity-90 text-white px-4 py-2 rounded transition-colors"
        >
          Add Instructions
        </button>
      </div>
    </div>
  );
};

// Define AIProcessingPage outside of App component
const AIProcessingPage = ({ handleContinueToAI, setIsProcessing }) => {
  const navigate = useNavigate();

  const handleContinue = async () => {
    await handleContinueToAI();
    navigate('/ai-output');
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Please click Send to AI to continue </h2>
      <div className="flex space-x-4">
        <button
          onClick={() => navigate('/upload')}
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
        >
          Cancel 
        </button>
        <button 
          onClick={handleContinue}
          className="bg-custom-blue hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded transition-colors"
        >
          Send to AI
        </button>
      </div>
    </div>
  );
};

export default App;
