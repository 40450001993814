import React from 'react';

const ProcessingInterface = ({ message }) => {
  return (
    <div className="processing-interface fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg flex flex-col items-center">
        <div className="loader mb-4"></div>
        <p className="text-lg">{message}</p>
      </div>
    </div>
  );
};

export default ProcessingInterface;
