import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const InstructionInput = ({
  onInstructionsComplete,
  setIsProcessing,
  savedDatasets = [],
  onSaveDataset
}) => {
  const [fields, setFields] = useState([{ column: '', description: '' }]);
  const [noteForAI, setNoteForAI] = useState('');
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [error, setError] = useState('');
  
  const navigate = useNavigate();

  // Function to add a new field
  const addField = () => {
    setFields([...fields, { column: '', description: '' }]);
  };

  // Function to remove a field by index
  const removeField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  // Function to handle changes in fields
  const handleFieldChange = (index, key, value) => {
    const newFields = [...fields];
    newFields[index][key] = value;
    setFields(newFields);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (fields.length === 0) {
      setError('Please add at least one column and description.');
      return;
    }

    for (let i = 0; i < fields.length; i++) {
      if (!fields[i].column.trim() || !fields[i].description.trim()) {
        setError('All columns and descriptions must be filled out.');
        return;
      }
    }

    setIsProcessing(true);
    setError('');

    try {
      // Send POST request to the backend
      const response = await fetch(`${config.apiUrl}/process_instructions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          columns: fields.map(f => f.column),
          descriptions: fields.map(f => f.description),
          noteForAI
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Processing failed');
      }

      const result = await response.json();

      if (!result.file_id) {
        throw new Error('Invalid response from server: Missing file_id');
      }

      onInstructionsComplete(result.file_id);
      navigate('/ai-processing');
    } catch (error) {
      console.error('Submission Error:', error);
      setError(error.message || 'An unexpected error occurred.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Function to save the current dataset
  const saveDataset = () => {
    if (!Array.isArray(savedDatasets)) {
      console.error('savedDatasets is not an array:', savedDatasets);
      return;
    }

    if (savedDatasets.length >= 5) {
      alert('You have reached the maximum limit of 5 saved datasets. Please delete one to save a new dataset.');
      return;
    }

    const newDataset = {
      id: Date.now().toString(),
      name: `Dataset ${savedDatasets.length + 1}`,
      columns: fields.map(f => f.column),
      descriptions: fields.map(f => f.description),
      noteForAI,
      lastModified: new Date().toISOString()
    };

    onSaveDataset([...savedDatasets, newDataset]);
    setSelectedDataset(newDataset.id);
    alert('Dataset saved successfully!');
  };

  // Function to load a selected dataset
  const loadDataset = (datasetId) => {
    const dataset = savedDatasets.find(d => d.id === datasetId);
    if (dataset) {
      setFields(dataset.columns.map((column, index) => ({
        column,
        description: dataset.descriptions[index]
      })));
      setNoteForAI(dataset.noteForAI);
      setSelectedDataset(datasetId);
    }
  };

  // Function to rename a dataset
  const renameDataset = (datasetId, newName) => {
    if (!newName) {
      alert('Dataset name cannot be empty.');
      return;
    }
    const updatedDatasets = savedDatasets.map(d => 
      d.id === datasetId ? { ...d, name: newName } : d
    );
    onSaveDataset(updatedDatasets);
  };

  // Function to delete a dataset
  const deleteDataset = (datasetId) => {
    const updatedDatasets = savedDatasets.filter(d => d.id !== datasetId);
    onSaveDataset(updatedDatasets);
    if (selectedDataset === datasetId) {
      setSelectedDataset(null);
    }
  };

  return (
    <div className="instruction-input max-w-2xl mx-auto p-4 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-custom-blue text-center">AI Instruction Configurator</h2>
      <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <div key={index} className="mb-4 bg-white p-3 rounded-lg shadow">
            <h3 className="text-base font-semibold text-custom-blue mb-2">Column {index + 1}</h3>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="block text-gray-700 text-sm mb-1">Column Name</label>
                <input
                  type="text"
                  value={field.column}
                  onChange={(e) => handleFieldChange(index, 'column', e.target.value)}
                  placeholder="Enter column name"
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-custom-blue"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-1">Description</label>
                <textarea
                  value={field.description}
                  onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                  placeholder="Enter description"
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-custom-blue"
                  rows="2"
                  required
                />
              </div>
            </div>
            {fields.length > 1 && (
              <button
                type="button"
                onClick={() => removeField(index)}
                className="mt-2 text-sm text-red-500 hover:text-red-700"
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={addField}
          className="w-full bg-custom-blue bg-opacity-20 text-custom-blue px-3 py-2 rounded-lg mb-4 hover:bg-opacity-30 transition-colors flex items-center justify-center text-sm"
        >
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          Add Field
        </button>
        <div className="mb-4 bg-white p-3 rounded-lg shadow">
          <h3 className="text-base font-semibold text-custom-blue mb-2">Note for AI</h3>
          <textarea
            value={noteForAI}
            onChange={(e) => setNoteForAI(e.target.value)}
            placeholder="Enter a note for the AI"
            rows="3"
            className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-custom-blue"
          />
        </div>
        <div className="mb-4 bg-white p-3 rounded-lg shadow">
          <h3 className="text-base font-semibold text-custom-blue mb-2">Use Existing Dataset</h3>
          <select 
            value={selectedDataset || ''}
            onChange={(e) => loadDataset(e.target.value)}
            className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-1 focus:ring-custom-blue"
          >
            <option value="">Select a dataset</option>
            {Array.isArray(savedDatasets) && savedDatasets.map(dataset => (
              <option key={dataset.id} value={dataset.id}>{dataset.name}</option>
            ))}
          </select>
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => {
                const newName = prompt('Enter new name for the dataset:', '');
                if (newName) renameDataset(selectedDataset, newName);
              }}
              className="flex-1 bg-custom-blue bg-opacity-20 text-custom-blue px-2 py-1 rounded-md hover:bg-opacity-30 transition-colors text-sm"
            >
              Rename
            </button>
            <button
              type="button"
              onClick={saveDataset}
              className="flex-1 bg-custom-blue text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-opacity-90 transition-colors"
            >
              Save Dataset
            </button>
            <button
              type="button"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this dataset?')) {
                  deleteDataset(selectedDataset);
                }
              }}
              className="flex-1 bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 transition-colors text-sm"
            >
              Delete
            </button>
          </div>
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <div className="flex space-x-3">
          <button
            type="button"
            onClick={() => navigate('/upload')}
            className="flex-1 bg-gray-300 text-gray-700 px-4 py-2 rounded-lg text-sm font-semibold hover:bg-gray-400 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 bg-custom-blue text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-opacity-90 transition-colors"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default InstructionInput;