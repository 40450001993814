import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloudUploadIcon, CogIcon, DownloadIcon } from 'lucide-react';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-custom-bg">
      <header className="px-4 lg:px-6 h-14 flex items-center border-b bg-white">
        <div className="flex items-center justify-center">
          <span className="ml-2 text-xl font-bold text-gray-900">Lausanne AI</span>
        </div>
        <nav className="flex ml-auto items-center space-x-4">
          <a href="#features" className="text-sm font-medium hover:text-custom-blue transition-colors">Features</a>
          <a href="#pricing" className="text-sm font-medium hover:text-custom-blue transition-colors">Pricing</a>
          <a href="#about" className="text-sm font-medium hover:text-custom-blue transition-colors">About</a>
          <a href="#contact" className="text-sm font-medium hover:text-custom-blue transition-colors">Contact</a>
          <button className="bg-custom-blue text-white px-4 py-2 rounded hover:bg-opacity-90 transition-colors">Sign Up</button>
        </nav>
      </header>

      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                Transform Your Data with <span className="text-custom-blue">Lausanne AI</span>
              </h1>
              <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl">
                Convert unstructured data to structured data with ease. Unlock insights and streamline your data
                processing workflow.
              </p>
              <div className="space-x-4">
                <button 
                  onClick={() => navigate('/upload')} 
                  className="bg-custom-blue text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-opacity-90 transition-colors"
                >
                  Get Started
                </button>
                <button className="bg-white text-custom-blue px-6 py-3 rounded-lg text-lg font-semibold border border-custom-blue hover:bg-custom-blue hover:bg-opacity-10 transition-colors">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">How It Works</h2>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-3 mt-8">
              <div className="flex flex-col items-center space-y-2 border p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow bg-white">
                <CloudUploadIcon className="h-12 w-12 text-custom-blue mb-2" />
                <h3 className="text-xl font-bold">Upload Data</h3>
                <p className="text-sm text-gray-600 text-center">
                  Easily upload your unstructured data in various formats.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow bg-white">
                <CogIcon className="h-12 w-12 text-custom-blue mb-2" />
                <h3 className="text-xl font-bold">Process</h3>
                <p className="text-sm text-gray-600 text-center">
                  Our advanced algorithms analyze and structure your data.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow bg-white">
                <DownloadIcon className="h-12 w-12 text-custom-blue mb-2" />
                <h3 className="text-xl font-bold">Download Results</h3>
                <p className="text-sm text-gray-600 text-center">
                  Get your structured data in easy-to-use formats.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-custom-blue bg-opacity-20">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">Get Started Today</h2>
            <div className="flex flex-col items-center space-y-4 text-center">
              <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl">
                Join thousands of satisfied users who have transformed their data with Lausanne AI.
              </p>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                  <input 
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue bg-white" 
                    placeholder="Enter your email" 
                    type="email" 
                  />
                  <button 
                    type="submit" 
                    className="bg-custom-blue text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors"
                  >
                    Sign Up
                  </button>
                </form>
                <p className="text-xs text-gray-600">
                  By signing up, you agree to our Terms & Conditions
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="w-full py-6 bg-white">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex items-center space-x-4">
              <span className="text-xl font-bold">Lausanne AI</span>
            </div>
            <nav className="flex gap-4 sm:gap-6">
              <a href="#terms" className="text-sm hover:underline underline-offset-4">Terms of Service</a>
              <a href="#privacy" className="text-sm hover:underline underline-offset-4">Privacy Policy</a>
              <a href="#contact" className="text-sm hover:underline underline-offset-4">Contact Us</a>
            </nav>
          </div>
          <div className="mt-4 text-center text-sm text-gray-600">
            © 2024 Lausanne AI. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;